<template>
  <!--  <h1>Active jobs</h1>-->
  <DataTable :value="Data" filterDisplay="row" sortField="creation_time" :sortOrder="-1"
             :paginator="true" :rows="lazyParams.limit"
             :loading="loading"
             @page="onPage($event)"
             paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown">
    <template #header>
      <div class="p-d-flex">
        <h2>Jobs</h2>
        <Button type="button" icon="pi pi-refresh" @click="loadData" class="p-button-text p-ml-auto"/>
      </div>
    </template>
    <template #empty>
      No jobs found.
    </template>
    <template #loading>
      Loading jobs data. Please wait.
    </template>
    <Column field="name" header="Name" :showFilterMenu="false" :sortable="true">
      <template #filter>
        <InputText v-model="lazyParams.filters.name" @change="doFilter" placeholder="Any" class="p-column-filter"/>
      </template>
    </Column>
    <Column field="task_id" header="Task Id" headerStyle="width: 8rem" :sortable="true"/>
    <Column field="status.value" header="Status" headerStyle="width: 10rem" :showFilterMenu="false" :sortable="true">
      <template #body="s">
        <Tag :value="s.data.status.value" :severity="s.data.status.severity"></Tag>
      </template>
      <template #filter>
        <Dropdown v-model="lazyParams.filters.status" @change="doFilter" :options="statuses" placeholder="Any"
                  class="p-column-filter" :showClear="true"/>
      </template>
    </Column>
    <Column field="duration.value" header="Duration" headerStyle="width: 10rem" :sortable="true">
      <template #body="s">
        <template v-if="s.data.duration">{{ s.data.duration.humanized }}</template>
        <template v-else>&mdash;</template>
      </template>
    </Column>

    <Column field="age" header="Age" headerStyle="width: 10rem">
      <template #body="s">
        {{ s.data.age.humanized }}
      </template>
    </Column>
    <Column field="creation_time" header="Creation time" headerStyle="width: 13rem" :sortable="true">
      <template #body="s">
        {{ printDate(s.data.creation_time) }}
      </template>
    </Column>
    <Column field="tags" header="Labels" headerStyle="width:13rem">
      <template #body="s">
        <template v-for="(value, name) in s.data.tags" v-bind:key="name">
          <Chip :label="name+' : '+value"></Chip>
        </template>
      </template>
    </Column>
    <Column headerStyle="width:10rem">
      <template #body="s">
          <span class="p-buttonset">
            <Button class="p-button-danger" icon="pi pi-trash" @click="doDeleteJob($event, s.data.name)"/>
            <Button class="p-button-help" icon="pi pi-search-plus" @click="doLogs(s.data.name)"/>
          </span>
      </template>
    </Column>
  </DataTable>
  <ConfirmPopup></ConfirmPopup>
  <LogDialog :key="logkey" :jobname="logjobname" v-if="logjobname"/>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import JobsService from "@/services/jobs";
import Chip from 'primevue/chip';
import Tag from 'primevue/tag';
import Dropdown from "primevue/dropdown";
import LogDialog from "@/components/LogDialog";

export default {
  components: {
    LogDialog,
    DataTable, Column, Chip, Tag, Dropdown
  },
  name: "ActiveJobs",
  service: null,
  data() {
    return {
      loading: true,
      logjobname: null,
      logkey: Math.random(),
      lazyParams: {
        offset: 0,
        limit: 10,
        filters: {
          status: 'Active',
          name: null,
        },
      },

      statuses: ['Active', 'Succeeded', 'Failed'],
      Data: []
    }
  },
  created() {
    this.service = new JobsService();
  },
  mounted() {
    this.loadData()
  },
  methods: {
    doFilter() {
      this.loadData()
    },
    onPage(e) {
      this.lazyParams.offset = e.first;
      this.loadData()
    },
    loadData() {
      this.Data = [];
      this.service.getJobs(this.lazyParams).then(response => {
            if (response === null) {
              this.loading = false;
              return
            }
            response.forEach(t => {
              this.Data.push(this.makeJob(t))
            })

            this.loading = false;
          }
      )
    },
    doLogs(name) {
      this.logjobname = name
      this.logkey = Math.random()
    },
    doDeleteJob(event, name) {
      this.$confirm.require({
        target: event.currentTarget,
        message: `Are you sure you want kill ${name} ?`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.Data = this.Data.filter(job => job.name !== name);

          this.service.getDeleteJob(name).then(data => {
            if (data.message === "ok") {
              this.$toast.add({
                severity: 'success',
                summary: 'Success Message',
                detail: 'Job deleted',
                life: 2000
              });
            }
          })
        },
      });
    },
    printDate(d) {
      if (d === null) return "-"
      return d.toLocaleTimeString() + " " + d.toLocaleDateString()
    },
  }
}
</script>