import axios from "axios";

export default class UACService {
    getUsers() {
        return axios.get('/api/users')
            .then(response => response.data)
    }

    getUser(user_id) {
        return axios.get('/api/users/' + user_id)
            .then(response => response.data)
    }

    createUser(data) {
        return axios.post("/api/users", data)
            .then(response => response.data)
    }

    deleteUser(user_id) {
        return axios.delete("/api/users/" + user_id)
            .then(response => response.data)
    }

    updateUser(user_id, data) {
        return axios.post("/api/users/" + user_id, data)
            .then(response => response.data)
    }

    Login(data) {
        return axios.post('/login', data)
            .then(respnse => respnse.data)
    }
    Logout() {
        return axios.get('/logout')
            .then(response => response.data)
    }
}