import axios from 'axios';

export default class ControlService {
    getRegisters() {
        return axios.get("api/registers")
            .then(response => response.data)
    }

    getRepositories (register) {
        return axios.get("api/repositories", {params:{"register":register}})
            .then(response => response.data)
    }

    getTags (repository) {
        return axios.get("api/tags", {params:{"repository": repository}})
            .then(response => response.data)
    }
}