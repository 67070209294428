<template>
  <h1 style="padding-left: 20px">{{ data.TaskName }}</h1>
  <form @submit.prevent="handleSubmit" class="p-fluid">
    <div class="p-grid" style="border-left: 10px solid lightgray; padding-left: 20px">
      <div class="p-col-6">

        <div class="p-field">
          <label for="name" :class="{'p-error':errors.TaskName}">Task Name</label>
          <InputText id="name" v-model="data.TaskName" type="text" :class="{'p-invalid':errors.TaskName}"/>
        </div>

        <div class="p-field">
          <label for="group">Group</label>
          <InputText id="group" v-model="data.GroupName" type="text"/>
        </div>

        <div class="p-field">
          <label for="name" :class="{'p-error':errors.DockerReg}">Docker Registry</label>
          <InputText id="name" v-model="data.DockerReg" type="text" :class="{'p-invalid':errors.DockerReg}"/>
        </div>

        <span>
            <div class="p-field">
                <label for="image" :class="{'p-error':errors.Image}">Input image</label>
                <InputText id="image"
                           v-model="data.Image"
                           placeholder="Input image name and tag"
                           :class="{'p-invalid':errors.Container}"/>
            </div>
          </span>

        <div class="p-field" v-if="data.Image">
          Image:
          <Chip :label="data.DockerReg + '/' + data.Image"></Chip>
        </div>

        <div class="p-field">
          <label for="command" :class="{'p-error':errors.Command}">Command</label>
          <InputText id="command" v-model="data.Command"
                     placeholder="Command to execute, example as ./app"
                     :class="{'p-invalid':errors.Command}"/>
        </div>


        <div class="p-field">
          <label for="args">Run arguments</label>
          <InputText id="args" v-model="data.Args" placeholder="Arguments"/>
        </div>

        <div class="p-field">
          <label for="envs">Environment variables</label>
          <Textarea id="args" v-model="data.Envs" rows="3" :autoResize="true" placeholder="ENVSTRING"/>
          <span class="p-text-light">Parameters per line</span>
        </div>

        <div class="p-field">
          <label for="ServiceAccountName">Service Account (use to run this pod)</label>
          <InputText id="service-account-name" v-model="data.ServiceAccountName" placeholder="default"/>
        </div>

        <div class="p-field">
          <label for="Tags">Tags</label>
          <MultiSelect 
          id="tags"
          v-model="data.Tags"
          :options="TagsOptions"
          optionLabel="Tag"
          optionValue="Id"
          placeholder="Select tags"
          />
        </div>

        <div class="p-field">
          <label for="TTLAfterDone">TTL After Done</label>
          <Dropdown v-model="selectedDurationTTL" :options="durationTTL" optionLabel="name" placeholder="TTL After Done" class="w-full md:w-14rem" />
        </div>

        <div class="p-field">
          <label for="alerting">Alerting</label><br>
          <InputSwitch id="alerting" v-model="Alerting"/>
        </div>


        <div class="p-field" v-if="Alerting">
          <label for="AlertingSettings" :class="{'p-error':errors.Alerting}">Mattermost channel</label>
          <InputText id="AlertingSettings" :class="{'p-invalid':errors.Alerting}" v-model="data.Alerting"/>
        </div>

        <div class="p-field">
          <label for="limits">Memory and CPU limits</label><br>
          <InputSwitch id="limits" v-model="SetLimits"/>
        </div>

        <div class="p-grid" v-if="SetLimits">

          <div class="p-field p-col-6">
            <label for="memlimit">Memory Limit</label>
            <InputText id="memlimit" v-model="data.MemLimit"/>
          </div>

          <div class="p-field p-col-6">
            <label for="memrequest">Memory Request</label>
            <InputText id="memrequest" v-model="data.MemRequest"/>
          </div>

          <div class="p-field p-col-6">
            <label for="cpulimits">CPU Limit</label>
            <InputText id="cpulimits" v-model="data.CPULimit"/>
          </div>


          <div class="p-field p-col-6">
            <label for="cpulimits">CPU Request</label>
            <InputText id="cpulimits" v-model="data.CPURequest"/>
          </div>

        </div>

        <div class="p-field">
          <label for="byAPI">Run by API</label><br>
          <InputSwitch id="byAPI" v-model="RunByAPI"/>
        </div>

        <div class="p-field" v-if="RunByAPI">
          <label :class="{'p-error':errors.APIHook}" for="APIhook">API Hook</label>
          <InputText id="APIhook" :class="{'p-invalid':errors.APIHook}" v-model="data.APIHook"
                     placeholder="API Hook"/>
        </div>

        <div class="p-field">
          <label for="byCron">Run by Cron</label><br>
          <InputSwitch id="byCron" v-model="RunByCron"/>
        </div>

        <div class="p-field" v-if="RunByCron">
          <Message severity="error" v-if="errors.CronSettings">You need to specify at least one value</Message>
          <template v-if="data.CronSettings.length > 0">
            <template v-for="(c, i) in data.CronSettings" v-bind:key="c.Id">
              <div class="p-formgroup-inline">
                <div class="p-field">
                  <InputText :value="c.Record" v-model="data.CronSettings[i].Record" placeholder="As example 0 1 * * *" style="min-width: 300px"/>
                </div>
                <div class="p-field">
                  <Button @click="deleteCron(i)" v-if="data.CronSettings.length != 1" icon="pi pi-minus" class="p-button-rounded p-button-text"/>
                </div>
                <div class="p-field" v-if="data.CronSettings.length-1 === i">
                  <Button @click="data.CronSettings.push({Record: '', Id:null})" icon="pi pi-plus" class="p-button-rounded p-button-text"/>
                </div>
              </div>
            </template>
          </template>
          <a href="https://crontab.guru/" style="margin-top: 10px; display: block" target="_blank">Online cron settings constructor</a>
        </div>

        <div class="p-field">
          <label for="immediately">Run immediately</label><br>
          <InputSwitch id="immediately" v-model="data.RunImmediately"/>
        </div>

        <Message severity="warn" :closable="false" v-if="data.RunImmediately">The task will be started immediately after creation.</Message>

        <div class="p-field">
          <label for="checkHost">Check host</label><br>
          <InputSwitch id="checkHost" v-model="CheckHost" @change="checkHost"/>
        </div>

        <div class="p-field" v-if="CheckHost">          
          <div class="p-formgroup-inline">
            <div class="p-field">
              <InputText v-model="data.URLHost" placeholder="url" style="min-width: 300px"/>
            </div>
            <Dropdown 
              filterPlaceholder="Status Host" 
              :options="StatusesHost"
              v-model="data.StatusHost" 
              optionLabel="title" 
              optionValue="value"
              id="statusHost"
            />
          </div>
        </div>


        <Message :closable="false">Attention! After saving the task settings, the old task will be deleted along with all related jobs.</Message>
        <div class="p-col-7 p-p-0">
              <span class="p-buttonset">
                <Button type="submit" :disabled='isDisabled' label="Update task"/>
                <Button class="p-button-text" @click="$router.back()" label="Cancel"/>
              </span>
        </div>
      </div>
    </div>
  </form>
</template>

<script>

import ControlService from "@/services/control";
import TasksService from "@/services/tasks";
import TaggitService from "@/services/tags";
import Chip from 'primevue/chip';
import InputSwitch from 'primevue/inputswitch'
import Message from 'primevue/message'
import Textarea from 'primevue/textarea';
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';
import {StatusesHost, durationTTL, SetDurationTTL} from '../utils/constants';

const cronregex = new RegExp(/^(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\d+(ns|us|µs|ms|s|m|h))+)|((((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7})$/);

export default {
  name: "EditTask",
  components: {Chip, InputSwitch, Message, Textarea, MultiSelect, Dropdown},
  service: null,
  taskService: null,
  tagService: null,
  isDisabled: null,
  props: ["task_id"],
  data() {
    return {
      errors: {
        DockerReg: false,
        TaskName: false,
        Command: false,
        APIHook: false,
        CronSettings: false,
        Repository: false,
        Tag: false,
        Alerting: false,
      },
      data: {
        TaskName: null,
        GroupName: null,
        DockerReg: null,
        Image: null,
        MemLimit: null,
        MemRequest: null,
        CPULimit: null,
        CPURequest: null,
        CronSettings: [],
        APIHook: null,
        Command: null,
        Args: null,
        Envs: null,
        Alerting: null,
        RunImmediately: false,
        ServiceAccountName: null,
        Tags: [],
        URLHost: null,
        StatusHost: null,
        TTLAfterDone: 0,
      },
      SetLimits: false,
      RunByAPI: false,
      RunByCron: false,
      Alerting: false,
      CheckHost: false,
      TagsOptions: [],
      StatusesHost,
      durationTTL,
      selectedDurationTTL: null,
    }
  },
  created() {
    this.isDisabled = false;
    this.service = new ControlService();
    this.taskService = new TasksService();
    this.tagService = new TaggitService()

    this.tagService.getTags().then(data => {
        this.TagsOptions = data
    })
    this.taskService.getTask(this.task_id).then(data => {
      this.data = data;
      this.RunByCron = !!(this.data.CronSettings)
      this.RunByAPI = !!(this.data.APIHook)
      this.Alerting = !!(this.data.Alerting)
      this.SetLimits = !!(this.data.MemLimit || this.data.MemRequest || this.data.CPULimit || this.data.CPURequest)
      this.CheckHost = !!(this.data.URLHost)
      if (data.CronSettings === null) {
        this.data.CronSettings = [{Record: "", Id:null}]
      }
      this.selectedDurationTTL = SetDurationTTL(this.data.TTLAfterDone)
    })
  },
  methods: {
    deleteCron (idx) {
      this.data.CronSettings = this.data.CronSettings.filter((_, i) => {
        if (i !== idx) {
          return true
        }
      })
    },
    isCronValid(freq) {
      return cronregex.test(freq);
    },
    checkCron() {
      let valid = true
      this.data.CronSettings.forEach(c => {
        if (this.isCronValid(c.Record) === false) valid = false
      })
      return valid
    },
    checkHost() {
      
      if (this.CheckHost === false) {
        this.data.StatusHost = null
        this.data.URLHost = null
      }
    },
    handleSubmit() {
      if (this.isDisabled === true) return

      this.errors.TaskName = !this.data.TaskName;
      this.errors.Command = !this.data.Command;
      this.errors.DockerReg = !this.data.DockerReg;
      this.errors.Image = !this.data.Image;
      this.errors.APIHook = (this.RunByAPI && !this.data.APIHook);
      this.errors.CronSettings = (this.RunByCron && !this.checkCron());
      this.errors.Alerting = (this.Alerting && !this.data.Alerting)

      let result = true;
      Object.values(this.errors).forEach(t => {
        if (t === true) result = false
      })

      this.data.CronSettings = (this.RunByCron) ? this.data.CronSettings : null
      this.data.APIHook = (this.RunByAPI) ? this.data.APIHook : null
      this.data.Alerting = (this.Alerting) ? this.data.Alerting : null
      this.data.CPULimit = (this.SetLimits) ? this.data.CPULimit : null
      this.data.CPURequest = (this.SetLimits) ? this.data.CPURequest : null
      this.data.MemLimit = (this.SetLimits) ? this.data.MemLimit : null
      this.data.MemRequest = (this.SetLimits) ? this.data.MemRequest : null
      this.data.TTLAfterDone = parseInt(this.selectedDurationTTL.code)

      if (result === true) {
        this.isDisabled = true;
        this.taskService.updateTask(this.task_id, this.data).then(data => {
          if (data.message === "ok") {
            this.$toast.add({severity: 'success', summary: 'Success Message', detail: 'Task updated', life: 2000});
            this.$router.push({name: "home"})
          } else {
              this.$toast.add({
                severity: 'error',
                summary: 'Error Message',
                detail: data.message,
                life: 2000
              });
          }
          this.isDisabled = false;
        }).catch(data => {
          if (data.response.data) {
            this.$toast.add({severity: 'error', summary: 'Error', detail: data.response.data.message, life: 2000})
          }
          this.isDisabled = false;
        })
      }

      return false
    },
    getRepositories: function () {
      this.Repositories = []
      this.Tags = []
      this.data.Repository = null
      this.data.Tag = null
      this.data.Image = ""
      this.service.getRepositories(this.data.DockerReg).then(data => {
        data.forEach(d => {
          this.Repositories.push({"Name": d, "Code": d})
        });
      })
    },
    getTags: function () {
      this.Tags = []
      this.data.Image = ""
      this.service.getTags(this.data.Repository).then(data => {
        data.forEach(d => {
          this.Tags.push({"Name": d, "Code": d})
        })
      })
    }
  }
}
</script>