import axios from "axios";

export default class TaggitService {
    getTags() {
        return axios.get('/api/taggit/tags')
            .then(response => response.data)
    }

    getTag(tag_id) {
        return axios.get('/api/taggit/tags/'+tag_id)
            .then(response => response.data)
    }

    createTag(data) {
        return axios.post("/api/taggit/tags", data)
            .then(response => response.data)
    }

    deleteTag(tag_id) {
        return axios.delete("/api/taggit/tags/"+tag_id)
            .then(response => response.data)
    }
}