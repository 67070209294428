import axios from "axios";

export default class JobsService {
    getJobs(data) {
        return axios.post("api/jobs", data)
            .then(response => response.data)
    }

    getDeleteJob(jobname) {
        return axios.delete("api/jobs", {params:{name:jobname}})
            .then(response => response.data)
    }

    getLogs(jobname) {
        return axios.get('/api/jobs/logs', {params:{name:jobname}})
            .then(response => response.data)
    }
    deleteDoneJobs(task_id) {
        return axios.delete("/api/task/"+task_id+"/jobs", {params:{status:'Succeeded,Failed'}})
            .then(response => response.data)
    }

}