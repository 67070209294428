import axios from "axios";

export default class TasksService {
    getTasks (lazyParams) {
        return axios.get("api/tasks", {params:{...lazyParams}})
            .then(response => response.data)
    }

    getJobsForTask(task_id) {
        return axios.post("api/jobs", {filters: {"task_id": task_id}})
            .then(response => response.data)
    }

    getTask(task_id) {
        return axios.get("api/tasks/"+task_id)
            .then(response => response.data)
    }

    createTask(data) {
        return axios.post("api/task", data)
            .then(response => response.data)
    }

    updateTask(task_id, data) {
        return axios.post("api/task/"+task_id, data)
            .then(response => response.data)
    }

    deleteTask(id) {
        return axios.delete("api/task/"+id)
            .then(response => response.data)
    }

    runTask(id) {
        return axios.get("api/task/"+id+"/start")
            .then(response => response.data)
    }

    setTaskStatus(id, status) {
        if (status === 3) {
            return axios.get("api/tasks/"+id+"/pause").then(response => response.data)
        } else {
            return axios.get("api/tasks/"+id+"/resume").then(response => response.data)
        }
    }

}