import {createRouter, createWebHistory} from 'vue-router'
import Tasks from "../views/Tasks";
import AddTask from "../views/AddTask";
import EditTask from "../views/EditTask";
import Jobs from "../views/Jobs"
import Users from "../views/Users"
import EditUser from "../views/EditUser"
import Login from "../components/Login"
import Logout from "../components/Logout"
import Tags from "../views/Tags"
import AddTag from "../views/AddTag"

const routes = [
    {
        path: '/',
        redirect: {name:"home"}
    },
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/logout",
        name: "logout",
        component: Logout
    },
    {
        path: '/tasks',
        name: 'home',
        props:true,
        component: Tasks
    },
    {
        path: '/tasks/add',
        name: 'addtask',
        component: AddTask
    },
    {
        path: '/tasks/:task_id',
        name: 'edittask',
        props:true,
        component: EditTask
    },
    {
        path: '/users',
        name: 'users',
        component: Users
    },
    {
        path: '/users/add',
        name: 'adduser',
        component: EditUser
    },
    {
        path: '/users/:user_id',
        name: 'edituser',
        component: EditUser,
        props:true,
    },
    {
        path: '/jobs',
        name: 'jobs',
        component: Jobs
    },
    {
        path: '/tags',
        name: 'tags',
        component: Tags
    },
    {
        path: '/tags/add',
        name: 'addtag',
        component: AddTag
    }
]

export const router = createRouter({
    history: createWebHistory(),
    routes
})