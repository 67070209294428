import { createApp } from 'vue'
import { router } from './router'
import axios from 'axios'

import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import PrimeVue from 'primevue/config';
import App from './App.vue'
import Button from "primevue/button";
import Tooltip from 'primevue/tooltip';
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Toast from "primevue/toast";
import ConfirmPopup from "primevue/confirmpopup";

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import moment from 'moment';

const app = createApp(App)

app.mixin(
    {
        methods: {
            getUser() {
                if (localStorage.User) {
                    return JSON.parse(localStorage.User)
                } else return {}
            },
            getDuration(s) {
                return moment.duration(s, "seconds").humanize(true)
            },
            getLabel(s) {
                if (s === "Active") return 'info'
                if (s === "Succeeded") return 'success'
                if (s === "Failed") return 'danger'
            },
            makeJob: function (t) {
                let task_id = t.tags.task_id;
                let tags = t.tags;
                delete tags.task_id;
                return {
                    name: t.name,
                    status: {
                        value: t.status,
                        severity: this.getLabel(t.status)
                    },
                    task_id: task_id,
                    creation_time: new Date(t.creation_time),
                    completion_time: (t.completion_time !== null) ? new Date(t.completion_time) : null,
                    tags: tags,
                    duration: {
                        value: t.duration,
                        humanized: this.getDuration(t.duration)
                    },
                    age: {
                        value: t.age,
                        humanized: this.getDuration(t.age)
                    }
                }
            }
        }
    }
)

app.directive('tooltip', Tooltip)
app.component("Button", Button)
app.component("Dropdown", Dropdown)
app.component("InputText", InputText)
app.component("Toast", Toast)
app.component("ConfirmPopup", ConfirmPopup)

app.use(ConfirmationService);
app.use(router)
app.use(ToastService)
app.use(PrimeVue, { ripple: true })
app.mount('#app')

axios.interceptors.request.use((request) => {
    request.headers['X-Auth-Token'] = localStorage.AuthToken
    return request
})

axios.interceptors.response.use((response) => {
    return response
},
    function (error) {
        if (error.response.status === 403) {
            router.push({ name: "login" })
            return false
        }
        return Promise.reject(error);
    })

if (process.env.VUE_APP_ENV_DEBUG === "1") {
    axios.defaults.baseURL = "http://localhost:9090"
} else {
    axios.defaults.baseURL = "/"
}
