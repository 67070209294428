<template>
  <template v-if="!isLogin">
    <Menubar :model="items">
      <template #end>
        <span class="user-info">{{getUser().Email}} <span class="superuser" v-if="getUser().Superuser">Admin</span></span>
        <Button label="Exit" @click="$router.push({name:'logout'})" class="p-button-link"/>
      </template>
    </Menubar>
    <Toast/>
    <main>
      <router-view/>
    </main>
  </template>
  <Login v-else />
</template>

<script>
import Menubar from 'primevue/menubar';
import Login from "@/components/Login";

export default {
  name: 'App',
  components: {
    Menubar, Login
  },
  data() {
    return {
      isLogin: false,
      items: [
        {label: 'Tasks', icon: 'pi pi-fw pi-home', to: '/'},
        {label: 'Active jobs', icon: 'pi pi-fw pi-calendar', to: '/jobs'},
        {label: 'Users', icon: 'pi pi-fw pi-users', to: '/users'},
        {label: 'Tags', icon: 'pi pi-fw pi-tags', to: '/tags'},
      ]
    }
  },
  watch: {
    '$route': function (v) {
      this.isLogin = v.name === "login"
    }
  }
}
</script>

<style>
html, body {
  font-size: 14px;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app h1 {
  color: dimgray;
  font-weight: normal;
}

#app h2 {
  padding: 0;
  margin: 0;
  font-weight: normal;
}

.user-info {
  padding: 0.5rem 1rem;
  display: inline-block;
}

.user-info .superuser {
  background-color: dodgerblue;
  color:white;
  padding:3px;
  font-size:9pt;
  border-radius: 3px;
}
</style>
