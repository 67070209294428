<template>
    <h1 style="padding-left: 20px">New tag</h1>
  
    <form @submit.prevent="handleSubmit" class="p-fluid">
      <div class="p-grid" style="border-left: 10px solid lightgray; padding-left: 20px">
        <div class="p-col-6">
  
          <div class="p-field">
            <label for="tag" :class="{'p-error':errors.Tag}">Tag</label>
            <InputText id="tag" v-model="data.Tag" type="text" :class="{'p-invalid':errors.Tag}"/>
          </div>
  
          <div class="p-col-7 p-p-0">
                <span class="p-buttonset">
                  <Button type="submit" label="Save tag"/>
                  <Button class="p-button-text" @click="$router.back()" label="Cancel"/>
                </span>
          </div>
  
        </div>
      </div>
    </form>
  </template>
  <script>
  
  import TaggitService from "@/services/tags";
  
  export default {
    name: "AddTag",
    service: null,
    data() {
      return {
        errors: {
          Tag: false,
        },
        data: {
          Tag: null,
        }
      }
    },
    created() {
      this.service = new TaggitService();
    },
    methods: {
      handleSubmit() {
        this.errors.Tag = !this.data.Tag;
        let result = true;
        Object.values(this.errors).forEach(t => {
          if (t === true) result = false
        })
  
        if (result === true) {
  
          let fnx = this.service.createTag(this.data)
  
          fnx.then(data => {
            if (data.message === "ok") {
              this.$toast.add({
                severity: 'success',
                summary: 'Success Message',
                detail: 'Tag created',
                life: 2000
              });
              this.$router.push({name: "tags"})
            } else {
              this.$toast.add({
                severity: 'error',
                summary: 'Error',
                detail: data.message,
                life: 2000
              });
            }
          }).catch(data => {
            if (data.response.data) {
              this.$toast.add({severity: 'error', summary: 'Error', detail: data.response.data.message})
            }
          })
        }
        return false
      }
    }
  }
  </script>