<template>

  <DataTable :value="Data" v-model:filters="filters" filterDisplay="row" sortField="Added" :sortOrder="-1"
    :loading="loading">
    <template #header>
      <div class="p-d-flex">
        <h2>Users</h2>
        <span class="p-buttonset  p-ml-auto">
          <Button type="button" icon="pi pi-refresh" @click="loadUsers" class="p-button-text" />
          <Button type="button" icon="pi pi-plus" @click="$router.push({name:'adduser'})" class="p-button-text" />
        </span>
      </div>
    </template>
    <template #empty>
      No users found.
    </template>
    <template #loading>
      Loading users. Please wait.
    </template>

    <Column field="Id" header="Id"  :sortable="true" headerStyle="width:5rem"/>

    <Column field="Email" header="Email" :showFilterMenu="false" :sortable="true">
      <template #filter="{filterModel,filterCallback}">
        <InputText v-model="filterModel.value" @keydown="filterCallback()" placeholder="Any" class="p-column-filter"/>
      </template>
      <template #body="s">
        <router-link :to="{name:'edituser', params:{user_id: s.data.Id}}">{{s.data.Email}}</router-link>
      </template>
    </Column>
    <Column field="TaskCount" header="Task count" :sortable="true"/>
    <Column field="Superuser" header="Is Superuser" :sortable="true">
      <template #body="{data}">
        <i class="pi" :class="{'true-icon pi-check-circle': data.Superuser, 'false-icon pi-times-circle': !data.Superuser}"></i>
      </template>
    </Column>
    <Column field="Added" header="Added"  :sortable="true" headerStyle="width:16rem">
      <template #body="s">
        {{ printDate(s.data.Added) }}
      </template>
    </Column>

    <Column field="LastLogin" header="Last Login"  :sortable="true" headerStyle="width:16rem">
      <template #body="s">
        {{ printDate(s.data.LastLogin) }}
      </template>
    </Column>

    <Column field="Disabled" header="Disabled"  :showFilterMenu="false" :sortable="true" dataType="boolean" headerStyle="width:7rem">
      <template #body="{data}">
        <i class="pi" :class="{'true-icon pi-check-circle': data.Disabled, 'false-icon pi-times-circle': !data.Disabled}"></i>
      </template>
      <template #filter="{filterModel,filterCallback}">
        <TriStateCheckbox v-model="filterModel.value" @change="filterCallback()" class="p-column-filter"/>
      </template>
    </Column>
    <Column  headerStyle="width:5rem">
      <template #body="{data}">
        <Button v-if="data.Id !== getUser().Id && getUser().Superuser" @click="doDeleteUser($event, data.Id)" icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger" />
      </template>
    </Column>
  </DataTable>
  <ConfirmPopup></ConfirmPopup>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import UACService from "@/services/uac";
import TriStateCheckbox from 'primevue/checkbox'
import {FilterMatchMode} from 'primevue/api';

export default {
  components: {
    DataTable, Column, TriStateCheckbox
  },
  name: "Users",
  service: null,
  data() {
    return {
      loading: true,
      filters: {
        'Email': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'Disabled': {value: null, matchMode: FilterMatchMode.EQUALS},
      },
      Data: []
    }
  },
  created() {
    this.service = new UACService();
  },
  mounted() {
    this.loadUsers()
  },
  methods: {
    loadUsers() {
      this.Data = [];
      this.service.getUsers().then(data => {
            if (data === null) {
              return
            }
            this.Data = data
            this.loading = false
          }
      )
    },
    doDeleteUser(event, id) {
      this.$confirm.require({
        target: event.currentTarget,
        message: `Are you sure you want delete user ?`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.service.deleteUser(id).then(data => {
            if (data.message === "ok") {
              this.loadUsers()
              this.$toast.add({
                severity: 'success',
                summary: 'Success Message',
                detail: 'User deleted',
                life: 2000
              });
            } else {
              this.$toast.add({
                severity: 'error',
                summary: 'Delete error',
                detail: data.message,
                life: 2000
              });
            }
          })
        },
      });
    },
    printDate(d) {
      if (d === null) return "-"
      d = new Date(d)
      return d.toLocaleTimeString() + " "+d.toLocaleDateString()
    },
  }
}
</script>