<template>

    <DataTable :value="Data" v-model:filters="filters" filterDisplay="row" sortField="Added" :sortOrder="-1"
      :loading="loading">
      <template #header>
        <div class="p-d-flex">
          <h2>Tags</h2>
          <span class="p-buttonset  p-ml-auto">
            <Button type="button" icon="pi pi-refresh" @click="loadTags" class="p-button-text" />
            <Button type="button" icon="pi pi-plus" @click="$router.push({name:'addtag'})" class="p-button-text" />
          </span>
        </div>
      </template>
      <template #empty>
        No tags found.
      </template>
      <template #loading>
        Loading tags. Please wait.
      </template>
  
      <Column field="Id" header="Id"  :sortable="true" headerStyle="width:5rem"/>
  
      <Column field="Tag" header="Tag" :showFilterMenu="false" :sortable="true">
      </Column>
      <Column field="TaskCount" header="Task count" :sortable="true"/>
  
      <Column  headerStyle="width:5rem">
        <template #body="{data}">
          <Button v-if="getUser().Superuser" @click="doDeleteTag($event, data.Id)" icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger" />
        </template>
      </Column>
    </DataTable>
    <ConfirmPopup></ConfirmPopup>
  </template>
  
  <script>
  import DataTable from 'primevue/datatable'
  import Column from 'primevue/column'
  import TaggitService from "@/services/tags";
  import {FilterMatchMode} from 'primevue/api';
  
  export default {
    components: {
      DataTable, Column
    },
    name: "Tags",
    service: null,
    data() {
      return {
        loading: true,
        filters: {
          'Tag': {value: null, matchMode: FilterMatchMode.CONTAINS},
        },
        Data: []
      }
    },
    created() {
      this.service = new TaggitService();
    },
    mounted() {
      this.loadTags()
    },
    methods: {
      loadTags() {
        this.Data = [];
        this.service.getTags().then(data => {
              this.loading = false
              if (data === null) {
                return
              }
              this.Data = data
            }
        )
      },
      doDeleteTag(event, id) {
        this.$confirm.require({
          target: event.currentTarget,
          message: `Are you sure you want delete tag ?`,
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.service.deleteTag(id).then(data => {
              if (data.message === "ok") {
                this.loadTags()
                this.$toast.add({
                  severity: 'success',
                  summary: 'Success Message',
                  detail: 'Tag deleted',
                  life: 2000
                });
              } else {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Delete error',
                  detail: data.message,
                  life: 2000
                });
              }
            })
          },
        });
      },
    }
  }
  </script>