<template>
  <Dialog :header="'Logs ' + jobname" :maximizable="true" v-model:visible="Visible">
    <div class="log" v-html="Log"/>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import JobsService from "@/services/jobs";

export default {
  name: "LogDialog",
  props: ["jobname", "key"],
  service: null,
  components:{Dialog},
  data() {
    return {
      Log: null,
      Visible: false,
    }
  },
  watch: {
    "key": function() {
      this.loadLogs()
    }
  },
  created() {
    this.service = new JobsService();
  },
  mounted() {
    this.loadLogs()
  },
  methods: {
    loadLogs: function (){
      this.Log = null
      this.service.getLogs(this.jobname).then(data => {
        this.Log = data.replace(/(?:\r\n|\r|\n)/g, '<br>');
        this.Visible = true;
      })
    },
  }
}
</script>

<style scoped>
.log {
  background-color: #262c2f;
  color: #d4d4d4;
  padding: 10px;
  line-height: 1.2;
  font-family: monospace;
  font-size: 10pt;
}
</style>