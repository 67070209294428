const StatusesHost = [
  {
    "title": "200",
    "value": 1
  },
  {
    "title": "Any response",
    "value": 2
  },
  {
    "title": "Not 500",
    "value": 3
  },
]

const durationTTL = [
  { name: '20 min', code: 1200 },
  { name: '1 h', code: 3600 },
  { name: '3 h', code: 10800 },
  { name: '6 h', code: 21600 },
  { name: '12 h', code: 43200 },
  { name: '24 h', code: 86400 },
  { name: '48 h', code: 172800 },
  { name: '72 h', code: 259200 },
  { name: '120 h', code: 432000 },
  { name: '168 h', code: 604800 },
]

function SetDurationTTL(TTLAfterDone = null) {
  const defaultTTLAfterDone = 1200
  let res = durationTTL.filter(x => x.code === TTLAfterDone)
  if (res.length === 0) {
    res = durationTTL.filter(x => x.code === defaultTTLAfterDone)
  }
  return { ...res[0] }

}
export { StatusesHost, durationTTL, SetDurationTTL }