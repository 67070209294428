<template>
  <Message>Goodbye!</Message>
</template>

<script>

import Message from "primevue/message";

export default {
  name: "Logout",
  components:{Message},
  service: null,
  data() {
    return {
    }
  },
  created() {
    delete localStorage.AuthToken
    delete localStorage.User
    setTimeout(() => this.$router.push({name:"login"}), 1000)
  },
}
</script>