<template>


  <DataTable :value="Tasks"
             v-model:expandedRows="ExpandedRows"
             @rowExpand="onRowExpand" dataKey="Id"
             :paginator="true" :rows="lazyParams.limit"
             :lazy="true"
             @page="onPage($event)"
             :totalRecords="totalRecords"
             paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
             currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
             :loading="loading">
    <template #header>
      <div class="p-d-flex">
        <span class="p-input-icon-left mr-2">
          <i class="pi pi-search" />
          <InputText v-model="search" placeholder="Search task" @change="doFilterTasks" />
        </span>
        <span class="p-input-icon-left">
          <MultiSelect 
          v-model="tags"
          :options="tagsOptions"
          optionLabel="Tag"
          optionValue="Id"
          placeholder="Select tags"
          @change="doFilterTasks"
          />
        </span>
        <span class="p-buttonset  p-ml-auto">
          <Button type="button" icon="pi pi-refresh" @click="loadData" class="p-button-text" />
          <Button type="button" icon="pi pi-plus" @click="$router.push({name:'addtask'})" class="p-button-text" />
        </span>
      </div>
    </template>
    <template #empty>
      No Tasks
    </template>

    <Column :expander="true" headerStyle="width: 3rem"/>
    <Column field="Id" header="Id" headerStyle="width: 3rem"></Column>
    <Column field="GroupName" header="Group" headerStyle="width:10rem"/>
    <Column field="TaskName" header="Task" style="overflow-wrap: break-word;">
      <template #body="s">
          <Badge value="Periodic" v-if="s.data.CronSettings" />
          {{s.data.TaskName}}<br />

          <span class="image-name">{{s.data.ImageFull}}</span>
      </template>
    </Column>
<!--    <Column field="ImageFull" header="Image" headerStyle="width:25rem"></Column>-->
    <Column field="CommandWithArgs" header="Command" style="max-width: 58em;">
      <template #body="s">
        <span class="code">{{s.data.CommandWithArgs}}</span>
      </template>
    </Column>
    <Column field="Status" header="Status" headerStyle="width:10rem"></Column>
    <Column field="Author" header="Author"  headerStyle="width:15rem">
      <template #body="s">
        {{ s.data.Author.Email }}
      </template>
    </Column>
    <template #expansion="slotProps">
        <Panel header="Control">
          <span class="p-buttonset">
              <Button v-if="getUser().Superuser || getUser().Id === slotProps.data.Author.Id" label="Delete" class="p-button-sm p-button-danger" icon="pi pi-trash" @click="doDeleteTask($event, slotProps.data)"/>
              <Button label="Start Now" class="p-button-sm p-button-success" icon="pi pi-play" @click="onRunJob($event, slotProps.data)" />
              <template v-if="slotProps.data.CronSettings">
                <Button label="Pause" class="p-button-sm p-button-warning" icon="pi pi-pause" @click="doSetStatus(slotProps.data, 3)" v-if="slotProps.data.StatusInt !== 3"/>
                <Button label="Resume" class="p-button-sm p-button-warning" icon="pi pi-play" @click="doSetStatus(slotProps.data, 1)" v-else/>
              </template>
              <Button v-if="getUser().Superuser || getUser().Id === slotProps.data.Author.Id" label="Edit" class="p-button-sm p-button-info" icon="pi pi-pencil" @click="doEditTask(slotProps.data)"/>
          </span>
        </Panel>
        <Panel :collapsed="true" :toggleable="true" header="Task info">
          <table class="details">
            <tr v-for="(v,k) in slotProps.data" v-bind:key="k">
              <template v-if="k!=='jobs'">
                <td>{{ k }}</td>
                <td v-if="k !== 'CronSettings'">
                  {{ printFormat(k, v) }}
                </td>
                <td v-else>
                  <table v-if="v">
                    <tr v-for="{Record, CronNextRun} in v" v-bind:key="Record">
                      <td style="width: 120px" >{{Record}}</td>
                      <td>{{ getDuration(CronNextRun) }}</td>
                    </tr>
                  </table>
                  <span v-else>-</span>
                </td>
              </template>
            </tr>
          </table>
        </Panel>
        <Panel :toggleable="true" header="Task jobs">
          <div class="p-d-flex p-p-1">
            <Button type="button" icon="pi pi-refresh" @click="doLoadJobs(slotProps.data)" class="p-button-text p-ml-auto" />
            <Button v-if="getUser().Superuser || getUser().Id === slotProps.data.Author.Id" class="p-button-text" icon="pi pi-trash" title="Delete all successed jobs" @click="doDeleteDoneJobs($event, slotProps)" />
          </div>
          <DataTable class="jobs-table" :value="slotProps.data.jobs" :paginator="true" :rows="15"
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          >
            <template #empty>
              No jobs
            </template>

            <Column field="name" header="Name" />

            <Column field="status" header="Status"  >
              <template #body="s">
                <Tag :value="s.data.status.value" :severity="s.data.status.severity"></Tag>
              </template>
            </Column>

            <Column field="age" header="Age" >
              <template #body="s">
                {{ s.data.age.humanized }}
              </template>
            </Column>

            <Column field="duration" header="Duration" >
              <template #body="s">
                <template v-if="s.data.duration">{{s.data.duration.humanized}}</template>
                <template v-else>&mdash;</template>
              </template>
            </Column>

            <Column headerStyle="width: 8rem;"  >
              <template #body="s">
                <span class="p-buttonset">
                  <Button v-if="getUser().Superuser || getUser().Id === slotProps.data.Author.Id" icon="pi pi-trash" class="p-button-danger p-button-sm p-button-help" @click="doDeleteTaskJob($event, s.data.name, slotProps.data)"/>
                  <Button icon="pi pi-search-plus" class="p-button-sm p-button-help" @click="doLogs(s.data.name)"/>
                </span>
              </template>
            </Column>

          </DataTable>
        </Panel>

    </template>
  </DataTable>
  <ConfirmPopup></ConfirmPopup>
  <LogDialog :key="logkey" :jobname="logjobname" v-if="logjobname"/>
</template>

<script>
import DataTable from "primevue/datatable"
import Column from "primevue/column"
import ControlService from "@/services/control";
import TasksService from "@/services/tasks";
import JobsService from "@/services/jobs";
import TaggitService from "@/services/tags";
import Tag from 'primevue/tag';
import Badge from 'primevue/badge';
import LogDialog from "@/components/LogDialog";
import Panel from "primevue/panel"
import MultiSelect from 'primevue/multiselect';
import {StatusesHost} from '../utils/constants';


export default {
  components: {LogDialog, DataTable, Column, Tag, Badge, Panel, MultiSelect},
  name: "Tasks",
  commonService: null,
  taskService: null,
  jobsService: null,
  tagsService: null,
  data() {
    return {
      search:null,
      tags:null,
      tagsOptions:[],
      loading:true,
      ExpandedRows: [],
      Tasks: null,
      showLog: false,
      log: null,
      logjobname: null,
      logkey: Math.random(),
      lazyParams: {
        offset: 0,
        limit: 10,
        search: this.search,
        tags: this.tags,
      },
      totalRecords:0,
    }
  },
  watch: {
    showLog: function (v) {
      if (v === false) {
        this.log = null
      }
    }
  },
  created() {
    this.service = new ControlService()
    this.taskService = new TasksService()
    this.jobsService = new JobsService()
    this.tagsService = new TaggitService()
  },
  mounted() {
    this.loadData()
    this.loadTags()
  },
  methods: {
    doLoadJobs(task) {
      this.taskService.getJobsForTask(task.Id).then(data => {
        task.jobs = []
        if (data === null) {
          return
        }
        data.forEach(t => {
          task.jobs.push(this.makeJob(t))
        })
      })
    },
    onRowExpand(e) {
      this.doLoadJobs(e.data)
    },
    onPage(e) {
      this.lazyParams.offset = e.first;
      this.loadData()
    },
    printFormat(k, v) {
      if (k === "Added") v = new Date(v).toLocaleString()
      if (k === "Executed") {
        v = (v.Valid === true) ? new Date(v.Time).toLocaleString() : "-"
      }
      if (k === "Author") v = v.Email
      if (!v) v = "-"
      if (k === "StatusHost")  {
        let statusObj = StatusesHost.find(o => o.value === v);
        v = (statusObj === undefined) ? "-" : statusObj.title
      }
      return v
    },
    onRunJob(event, j) {
      this.$confirm.require({
        target: event.currentTarget,
        message: `Are you sure you want run ${j.TaskName} ?`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.taskService.runTask(j.Id).then(data => {
            if (data.message === "ok") {
              this.$toast.add({
                severity: 'success',
                summary: 'Success Message',
                detail: 'Job started',
                life: 2000
              });
              setTimeout(this.doLoadJobs, 2000, j)
            }
          })
        },
      });
    },
    doDeleteTaskJob(event, job, data){
      this.$confirm.require({
        target: event.currentTarget,
        message: `Are you sure you want delete ${job} ?`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          data.jobs = data.jobs.filter(val => val.name !== job)

          this.jobsService.getDeleteJob(job).then(data => {
            if (data.message === "ok") {
              this.$toast.add({
                severity: 'success',
                summary: 'Success Message',
                detail: 'Job deleted',
                life: 2000
              });

            }
          })
        },
      });
    },
    doSetStatus (task, status) {
      this.taskService.setTaskStatus(task.Id, status).then(data => {
        if (data.message === "ok") {
          task.StatusInt = status
          task.Status = (status === 1)?"Pending":"Stopped"

          this.$toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Task updated',
            life: 2000
          });
        }
      })
    },
    doDeleteDoneJobs(event, task){
      this.$confirm.require({
        target: event.currentTarget,
        message: `Are you sure you want delete all jobs with status successes ?`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.jobsService.deleteDoneJobs(task.data.Id).then(data => {
            if (data.message === "ok") {
              this.doLoadJobs(task.data)
            } else {
              this.$toast.add({
                severity: 'error',
                summary: "Permissions error",
                detail: data.message,
                life: 2000
              });
            }
          })
        }
      })
    },
    doFilterTasks() {
      this.lazyParams.search = this.search
      this.lazyParams.tags = this.tags
      this.loadData()
    },
    doEditTask(task) {
      this.$router.push({name:"edittask", params: {task_id : task.Id}})
    },
    doDeleteTask(event, j) {
      this.$confirm.require({
        target: event.currentTarget,
        message: `Are you sure you want delete ${j.TaskName} ?`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.taskService.deleteTask(j.Id).then(data => {
            if (data.message === "ok") {
              this.Tasks = this.Tasks.filter(job => job.Id !== j.Id);
              this.$toast.add({
                severity: 'success',
                summary: 'Success Message',
                detail: 'Job deleted',
                life: 2000
              });
            } else {
              this.$toast.add({
                severity: 'error',
                summary: "Permissions error",
                detail: data.message,
                life: 2000
              });
            }
          })
        },
      });
    },
    loadData() {
      this.loading = true;
      this.Tasks = []
      this.taskService.getTasks(this.lazyParams).then(data => {
        this.Tasks = data.tasks
        this.totalRecords = data.count
        this.loading = false;
      });
    },
    loadTags() {
      this.tagsService.getTags().then(data => {
        this.tagsOptions = data
      })
    },
    doLogs(job) {
      this.logjobname = job
      this.logkey = Math.random()
    },
  }
}
</script>
<style scoped>
.details {
  font-size: 10pt;
  background-color: white;
}

.details tr {
  border-bottom: 1px solid #e9ecef
}

.details tr td {
  padding: 10px;
}

.details tr td:first-child {
  font-weight: bold;
  width: 200px;
  background-color: #f8f9fa;
}
.image-name {
  font-family: monospace;
  background-color: #e9ecef;
  padding: 3px 6px;
  margin-top: 5px;
  display: inline-block;
  border-radius: 3px;
  overflow: auto;
  white-space: nowrap;
  width: 100%;
}

span.code {
  display: block;
  max-height: 63px;
  overflow-y: scroll;
  font-family: monospace;
  overflow-wrap: break-word;
}

.jobs-table thead .p-column-title  {
  display:block !important;
}

.p-datatable table {
  width: 100% !important;
}

.mr-2 {
  margin-right: .5rem !important;
}
</style>
