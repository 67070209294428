<template>
  <h1 style="padding-left: 20px" v-if="user_id">{{ data.Email }}</h1>
  <h1 style="padding-left: 20px" v-else>New user</h1>

  <form @submit.prevent="handleSubmit" class="p-fluid">
    <div class="p-grid" style="border-left: 10px solid lightgray; padding-left: 20px">
      <div class="p-col-6">

        <div class="p-field">
          <label for="email" :class="{'p-error':errors.Email}">Email</label>
          <InputText id="email" v-model="data.Email" type="text" :class="{'p-invalid':errors.Email}"/>
        </div>

        <div class="p-field">
          <label for="password" :class="{'p-error':errors.Password}">Password</label>
          <InputText type="password" placeholder="Input password for change" id="password" v-model="data.Password"
                     :class="{'p-invalid':errors.Password}"/>
        </div>

        <div class="p-field">
          <label for="disabled">Disabled</label><br/>
          <InputSwitch id="disabled" v-model="data.Disabled"/>
        </div>

        <div class="p-field" v-if="getUser().Superuser == true">
          <label for="superuser">Superuser</label><br/>
          <InputSwitch id="superuser" v-model="data.Superuser"/>
        </div>

        <div class="p-col-7 p-p-0">
              <span class="p-buttonset">
                <Button type="submit" label="Save user"/>
                <Button class="p-button-text" @click="$router.back()" label="Cancel"/>
              </span>
        </div>

      </div>
    </div>
  </form>
</template>
<script>

import UACService from "@/services/uac";
import InputSwitch from 'primevue/inputswitch'

export default {
  name: "EditUser",
  props: ["user_id"],
  components: {
    InputSwitch,
  },
  service: null,
  data() {
    return {
      errors: {
        Email: false,
        Password: false,
      },
      data: {
        Email: null,
        Password: null,
        Disabled: false,
        Superuser: false,
      }
    }
  },
  created() {
    this.service = new UACService();
  },
  mounted() {
    if (this.user_id) {
      this.service.getUser(this.user_id).then(data => {
        data.Password = null
        this.data = data;
      })
    }
  },
  methods: {
    handleSubmit() {
      this.errors.Email = !this.data.Email;
      if (!this.user_id) {
        this.errors.Password = !this.data.Password
      }

      let result = true;
      Object.values(this.errors).forEach(t => {
        if (t === true) result = false
      })

      if (result === true) {

        let fnx = (this.user_id) ? this.service.updateUser(this.user_id, this.data) : this.service.createUser(this.data)

        fnx.then(data => {
          if (data.message === "ok") {
            this.$toast.add({
              severity: 'success',
              summary: 'Success Message',
              detail: (this.user_id) ? 'User updated' : 'User created',
              life: 2000
            });
            this.$router.push({name: "users"})
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: data.message,
              life: 2000
            });
          }
        }).catch(data => {
          if (data.response.data) {
            this.$toast.add({severity: 'error', summary: 'Error', detail: data.response.data.message})
          }
        })
      }
      return false
    }
  }
}
</script>